





































































































import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';

export default Vue.extend({
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String as () => string,
    },
    label: {
      type: String as () => string,
    },
    loading: {
      type: Boolean as () => boolean,
    },
    'error-messages': {
      type: Array as () => string[],
    },
    readonly: {
      type: Boolean as () => boolean,
    },
  },

  data(): {
    uuid: string;
    editor?: Editor;
  } {
    return {
      uuid: uuidv4(),
      editor: undefined,
    };
  },

  mounted() {
    this.editor = new Editor({
      editable: !this.readonly,
      content: this.value,
      extensions: [StarterKit],
      onUpdate: this.update,
    });
  },

  beforeDestroy() {
    this.editor?.destroy();
  },

  watch: {
    value() {
      if (this.editor?.getHTML() !== this.value) {
        this.editor?.commands?.setContent(this.value, false);
      }
    },
    readonly() {
      this.editor?.setOptions({
        editable: !this.readonly,
      });
    },
  },

  methods: {
    update() {
      const json = this.editor?.getJSON();
      if (json?.content.some((row: any) => !!row.content)) {
        this.$emit('input', this.editor?.getHTML());
      } else {
        this.$emit('input', null);
      }
    },
  },
});
